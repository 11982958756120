import React from "react";
import NavTop from "../components/NavTop";

const test = () => {
  return (
    <NavTop />
  )
}

export default test;
